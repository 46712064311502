export default interface IPatientData {
    id: string,
    firstName: string,
    lastName: string,
    phone: string,
    email?: string,
    address?: string,
    city?: string,
    zip?: string,
    notes?: string,
    blacklisted?: boolean
}

export const defaultPatient: IPatientData = {
    id: "",
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    address: "",
    city: "",
    zip: "",
    notes: "",
    blacklisted: false
}