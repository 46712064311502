export const gemeinden = {
    "4303" : "Kaiseraugst", 
    "4305" : "Olsberg",
    "4310" : "Rheinfelden", 
    "4312" : "Magden",
    "4313" : "Möhlin",
    "4314" : "Zeiningen",   
    "4315" : "Zuzgen",
    "4316" : "Hellikon",    
    "4317" : "Wegenstetten",
    "4322" : "Mumpf",
    "4323" : "Wallbach",    
    "4324" : "Obermumpf",   
    "4325" : "Schupfart",   
    "4332" : "Stein",
    "4333" : "Münchwilen",  
    "4334" : "Sisseln",
    "4468" : "Kienberg",    
    "5027" : "Herznach",    
    "5028" : "Ueken",
    "5062" : "Oberhof",
    "5063" : "Wölflinswil", 
    "5064" : "Wittnau",
    "5070" : "Frick",
    "5072" : "Oeschgen",    
    "5073" : "Gipf-Oberfrick",
    "5074" : "Eiken",
    "5075" : "Hornussen",   
    "5076" : "Bözen",
    "5077" : "Elfingen",    
    "5078" : "Effingen",    
    "5079" : "Zeihen",
    "5080" : "Laufenburg",  
    "5082" : "Kaisten",
    "5084" : "Rheinsulz"
  }