import moment from "moment";
import ICalendar from "../types/calendar.type";
import { IGoogleEvent, IEvent, IReceiptEvent } from "../types/event.type";
import { genGoogleEventTitle } from "../utils/patient.utils";

class CalendarService {  
    
    getCalendar = async (id: string) => {
        const response = await gapi.client.calendar.calendarList.get({calendarId: id});
        return this.convertGoogleCalendar(response.result)
    }

    getAllCalendars = async () => {
        const response = await gapi.client.calendar.calendarList.list();
        return response.result.items?.filter(calendar => calendar.accessRole !== "reader")
        .map(calendar => this.convertGoogleCalendar(calendar))
    }

    getEvent = async (calendarId: string, eventId: string) => {
        return await gapi.client.calendar.events.get({calendarId: calendarId, eventId: eventId})
    }

    getAllEvents = async (calendars: ICalendar[], timeMin?: string, timeMax?: string, patientId?: string) => {
        return (await Promise.all(calendars.map(calendar => this.getEventsByCalendar(calendar, timeMin, timeMax, patientId)))).flat()
    }

    getAllEventsByPatient = async (patient: any) => {
        const calendars = await this.getAllCalendars();
        if (calendars) {
            const now = moment()
            const events = await this.getAllEvents(calendars, now.toISOString(), undefined, patient.id);
            return events
                        // google api's timeMin parameter is compared with the end date -> the query returns appointments
                        // which are on right now -> we need to filter them out ourselves. Now must be before the start date.
                        .filter(event => now.isBefore(event.start))
                        .map(event => ({
                            id: event?.id,
                            calendarId: event.calendarId,
                            employee: event.extendedProperties?.employee,
                            start: event.start, 
                            end: event.end,
                        }) as IReceiptEvent)

        }
    }

    createEvent = (newEvent: IGoogleEvent) => {
        if (newEvent.organizer && newEvent.patient) {
            const event = this.convertGoogleEvent(newEvent);
            return gapi.client.calendar.events.insert({calendarId: newEvent?.organizer.id}, event);
        }
    }

    updateEvent = (calendarId: string, eventId: string, updatedEvent: any) => {
        return gapi.client.calendar.events.update({calendarId: calendarId, eventId: eventId}, updatedEvent);
    }

    deleteEvent = (calendarId: string, eventId: string) => {
        return gapi.client.calendar.events.delete({calendarId: calendarId, eventId: eventId});
    }

    private getEventsByCalendar = async (calendar: ICalendar, timeMin?: string, timeMax?: string, patientId?: string) => {
        let pagedEvents: IEvent[] = [];
        let pageToken;
        do {
            const response: any = await gapi.client.calendar.events.list({calendarId: calendar.id, pageToken: pageToken, timeMin: timeMin, timeMax: timeMax, singleEvents: true, sharedExtendedProperty: patientId && `patientId=${patientId}`});
            if (response.result.items) {
                pagedEvents.push.apply(pagedEvents, response.result.items.map((event: any) => ({
                    id: event?.id,
                    calendarId: calendar.id,
                    description: event.description,
                    patientId: event.extendedProperties?.shared?.patientId,
                    title: event?.summary, 
                    start: event?.start?.dateTime || event?.start?.date, 
                    end: event?.end?.dateTime || event?.end?.date, 
                    allDay: !event?.start?.dateTime, 
                    url: event?.htmlLink,
                    color: calendar.backgroundColor,
                    isReadOnly: calendar.accessRole === "reader",
                    extendedProperties: {
                        employee: calendar.summary,
                        ...{patientId: event.extendedProperties?.shared?.patientId},
                        ...{label: event.extendedProperties?.shared?.label }
                    }
                   }) as IEvent))

                   pageToken = response.result.nextPageToken
            }
        } while (pageToken)
        return pagedEvents;
    }

    private convertGoogleCalendar = (googleCalendar: any) => {
        return {id: googleCalendar.id, 
                summary: googleCalendar.summary !== googleCalendar.id ? googleCalendar.summary : (googleCalendar.description ? googleCalendar.description : "Admin"), 
                backgroundColor: googleCalendar.backgroundColor,
                accessRole: googleCalendar.accessRole,
                selected: googleCalendar.selected} as ICalendar
    }

    private convertGoogleEvent = (event: IGoogleEvent) => {
        return {
            summary: genGoogleEventTitle(event) ,
            description: event.description,
            start: {
              ...(!event.allDay ? {dateTime: event?.start} : {date: event?.start})
            },
            end: {
                ...(!event.allDay ? {dateTime: event?.end} : {date: event?.end})
            },
            extendedProperties: {
                shared: {patientId: event.patient!.id, label: event.label || ""}
            }
        }
    }
  }
  
  export default new CalendarService();