import { createContext, useEffect, useState, useContext } from "react";
import IPatientData, { defaultPatient } from "../types/patient.type";
import PatientService from '../services/patients.service';

interface PatientContextInterface {
    searchInput: string;
    setSearchInput: any;
    patients: IPatientData[];
    selectedPatient: any;
    setSelectedPatient: any;
    reload: boolean;
    setReload: any
}

const PatientContext = createContext({
    searchInput: "",
    setSearchInput: (prevState: any) => undefined,
    patients: [] as IPatientData[],
    selectedPatient: {} as IPatientData,
    setSelectedPatient: (prevState: any) => undefined,
    reload: false,
    setReload: (prevState: boolean) => undefined
});

export const usePatients = () => useContext(PatientContext)

export const PatientContextProvider = ({children} : any) => {
    const [patients, setPatients] = useState<any>();
    const [selectedPatient, setSelectedPatient] = useState<IPatientData | undefined>();
    const [reload, setReload] = useState<boolean>(false);
    const [searchInput, setSearchInput] = useState<string>("")

    const fetchPatients = async () => {
        setPatients((await PatientService.getAll()).docs.map(doc => ({...defaultPatient, ...doc.data(), id: doc.id}) as IPatientData))
        setReload(false)
    }

    useEffect(() => {
        fetchPatients()
    }, [])

    useEffect(() => {
        reload && fetchPatients()
    }, [reload])
    
    const patientContext: PatientContextInterface = {
        searchInput,
        setSearchInput,
        patients,
        selectedPatient,
        setSelectedPatient,
        reload,
        setReload
    };

    return (
        <PatientContext.Provider value={patientContext}>
            {children}
        </PatientContext.Provider>
    )
}