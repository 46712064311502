import Sidebar from '../components/Sidebar/Sidebar'
import { Route, Switch } from 'react-router-dom';
import routes from '../routes/routes';
import { ToastContainer } from 'react-toastify';
import Overview from '../components/Patients/Overview';

const Main = () => {

    const getRoutes = (routes: any[]) => {
        return routes.map((prop, key) => {
            return (
                <Route
                    path={prop.layout + prop.path}
                    render={(props) => <prop.Component {...props} />}
                    exact
                    key={key} />
            );
        });
    };

    return (
        <div className='wrapper'>
            <ToastContainer />
            <Sidebar routes={routes}/>
            <div className="main-panel">
                <div className="content">
                    <Switch>
                        {getRoutes(routes)}
                        <Route 
                            path={'/dashboard/patients/:patientId'}
                            render={() => <Overview />}
                        />
                    </Switch>
                </div>
            </div>
        </div>
    );
}

export default Main;