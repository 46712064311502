import { GoogleAuthProvider, OAuthCredential, signInWithCredential } from 'firebase/auth';
import { createContext, useCallback, useContext, useEffect, useState} from 'react';
import CustomSpinner from '../components/Spinner/CustomSpinner';
import { auth } from '../firebase/config';

interface AuthContextInterface {
    currentUser: any;
    signInWithGoogle: () => any;
}

const AuthContext = createContext({
    currentUser: null,
    signInWithGoogle: () => Promise
})

export const useAuth = () => useContext(AuthContext);

export const AuthContextProvider = ({children} : any) => {
    const [currentUser, setcurrentUser] = useState<any>();
    const [isClientInitiated, setIsClientInitiated] = useState(false);

    const initClient = useCallback(
        async () => {
            const gapiConfig = {
                apiKey: process.env.REACT_APP_API_KEY,
                clientId: process.env.REACT_APP_CLIENT_ID,
                discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
                scope: 'https://www.googleapis.com/auth/calendar'
            }
            await gapi.client.init(gapiConfig);
            await gapi.client.load('calendar', 'v3');
            const tokenObject = gapi.client.getToken()
            if (tokenObject) {
                const gapiCurrentUser = gapi.auth2.getAuthInstance().currentUser.get()
                console.log("User already logged in: ", gapiCurrentUser)
                const credential = GoogleAuthProvider.credential(null, tokenObject.access_token)
                await firebaseLogin(credential)
            }
            setIsClientInitiated(true);
        },
      [],
    )

    useEffect(() => {
        gapi.load('client', initClient);
    }, [initClient])

    const firebaseLogin = async (credential : OAuthCredential) => {
        const firebaseCurrentUser = await signInWithCredential(auth, credential);
        console.log("Firebase: login", firebaseCurrentUser)
        setcurrentUser(firebaseCurrentUser.user)
    }

    const signInWithGoogle = async () => {
        const googleUser = await gapi.auth2.getAuthInstance().signIn();
        const token = googleUser.getAuthResponse().id_token
        const credential = GoogleAuthProvider.credential(token)
        firebaseLogin(credential)
    }

    const sampleAuthContext: AuthContextInterface = {
        currentUser,
        signInWithGoogle
    };

    return (
        <AuthContext.Provider value={sampleAuthContext}>
            {isClientInitiated ? (
                children
            ) : <CustomSpinner />}
        </AuthContext.Provider>
    )
}