import moment from "moment"
import { toast } from "react-toastify"
import messageService from "../services/message.service"
import { IReceiptEvent } from "../types/event.type"
import IMessage from "../types/message.type"
import IPatientData from "../types/patient.type"

export const getSMSBody = (separator: string, events: IReceiptEvent[]) => {
    let title = `Termine physio balance:${separator}`
    let body = events.map(event => {
        const start = moment(event.start).format("HH:mm")
        const end = moment(event.end).format("HH:mm")
        return `Datum: ${moment(event.start).format("DD.MM.YYYY")} ${start === end ? "Ganztägig" : `${start}`}, ${event.employee}`
    }).join(separator)
    let footer = `${separator}Im Verhinderungsfall bitte 24 Stunden vorher Bescheid geben (Tel. 0628711620)`
    return [title, body, footer].join(separator)
}

export const getEmailBody = (separator: string, events: IReceiptEvent[], patient: IPatientData) => {
    const title = `Guten Tag ${patient.firstName} ${patient.lastName}${separator}${separator}Gerne senden wir dir/ihnen folgende Termine:${separator}`
    const body = events.map(event => {
        const start = moment(event.start).format("HH:mm")
        const end = moment(event.end).format("HH:mm")
        return `${moment(event.start).format("DD.MM.YYYY")}        ${start === end ? "Ganztägig" : `${start} - ${end}`}        ${event.employee}`
    }).join(separator)
    const disclaimer = [`${separator}Im Verhinderungsfall bitte 24 Stunden vorher Bescheid geben.`, 'Unsere Leistungen werden direkt elektronisch mit der zuständigen Versicherung abgerechnet.', `Auf Wunsch können wir Ihnen eine zusätzliche Rechnungskopie ausstellen.${separator}`].join(separator)
    const footer = ['Viele Grüsse', `Team physio balance${separator}`, 'physio balance', 'Widengasse 16', '5070 Frick', '062 871 16 20', 'www.physiobalance.fit'].join(separator)
    return [title, body, disclaimer, footer].join(separator)
}

export const sendMessage = async (phone: string, message: string) => {
    await messageService.create({
        to: phone,
        body: message
    } as IMessage)
    toast.success("SMS wurde versendet!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        })
}