import { Page, Document, Image, StyleSheet } from '@react-pdf/renderer';
import logo from '../../assets/logo-pdf.png'
import AppointmentTitle from './ReceiptTitle';
import PatientInfo, { Patient } from './PatientInfo';
import AppointmentTable from './AppointmentTable/AppointmentTable';
import FinalMsg from './FinalMsg';
import IPatientData from '../../types/patient.type';

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft:60,
        paddingRight:60,
        lineHeight: 1.5,
        flexDirection: 'column',
    }, 
    logo: {
        height: 120,
        marginLeft: 'auto',
        marginRight: 'auto'
    }
  });

  export interface ReceiptProps {
    patient: Patient | IPatientData,
    items: any[]
  }

const Receipt = (receipt: ReceiptProps) => {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <Image style={styles.logo} src={logo} />
                <AppointmentTitle title={"Termine"}/>
                <PatientInfo patient={receipt.patient}/> 
                <AppointmentTable items={receipt.items} />
                <FinalMsg />
            </Page>
        </Document>
    )
};

export default Receipt;