import Dashboard from "../components/Dashboard/Dashboard";
import History from "../components/History/History";
import Patients from "../components/Patients/Patients";

export interface IRoute {
    path: string,
    name: string,
    icon: string,
    Component: React.FC,
    layout: string
}

const dashboardRoutes: IRoute[] = [
    {
        path: "/calendar",
        name: "Kalender",
        icon: "far fa-calendar-alt",
        Component: Dashboard,
        layout: "/dashboard"
    },
    {
        path: "/patients",
        name: "Patienten",
        icon: "fas fa-user-injured",
        Component: Patients,
        layout: "/dashboard"
    },
    {
        path: "/history",
        name: "SMS Verlauf",
        icon: "fas fa-history",
        Component: History,
        layout: "/dashboard"
    }
];

export default dashboardRoutes;
