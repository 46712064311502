import { isValidPhoneNumber } from "libphonenumber-js";

export const isValidPhone = (country: any, value: string) => {
    return isValidPhoneNumber(value, country.iso2.toUpperCase())
}

export const isPhoneEmpty = (country: any, value: string) => {
    if (value === country.dialCode || value.length === 0) {
        return true; // if value is country code => it's valid because the country code is removed later
    }
    return false
}