import { useHistory } from "react-router-dom";

interface ActionsFormatterProps {
    row: any,
    editPatient: any,
    deletePatient: any,
}

const ActionsFormatter = (props: ActionsFormatterProps) => {
    const history = useHistory()

    return(
        <div className='flex margin-top-3px'>
            <i onClick={() => history.push(`patients/${props.row.id}`)} className="fas fa-address-book action-icon icon-large margin-right-10px" />
            <i onClick={() => props.editPatient(props.row)} className="fas fa-pen action-icon icon-large margin-right-10px" />
            <i onClick={() => props.deletePatient(props.row.id)} className="far fa-trash-alt action-icon icon-large" />
        </div>
    )
};

export default ActionsFormatter;