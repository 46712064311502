import {View, StyleSheet } from '@react-pdf/renderer';
import AppointmentTableHeader from './AppointmentTableHeader';
import AppointmentTableRow, { ReceiptEvent } from './AppointmentTableRow';

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
        borderColor: '#198754',
    },
});

interface InvoiceItemsTableProps {
  items: ReceiptEvent[]
}

const InvoiceItemsTable = ({items}: InvoiceItemsTableProps) => (
  <View style={styles.tableContainer}>
      <AppointmentTableHeader />
      <AppointmentTableRow items={items} />
  </View>
);
  
  export default InvoiceItemsTable