import ICalendar from "./calendar.type";
import IPatientData from "./patient.type";

export interface IEvent {
    id: string,
    calendarId: string,
    title: string,
    start: string,
    end: string,
    allDay: boolean,
    url: string,
    color: string,
    isReadOnly: boolean
    extendedProperties?: {
        employee?: string,
        patientId?: string
    };
}

export interface IGoogleEvent {
    description?: string,
    organizer?: ICalendar,
    patient?: IPatientData,
    start?: string,
    startTime?: string,
    end?: string,
    endTime?: string,
    allDay?: boolean
    label?: Label
}

export enum Label {
    ERSTBEHANDLUNG = 'ERSTBEHANDLUNG', LYMPHDRAINAGE = 'LYMPHDRAINAGE', DOMIZIL = 'DOMIZIL', ABSAGE = 'ABSAGE', GLAD = 'GLAD'
}

export enum LabelTitlePrefix {
    ERSTBEHANDLUNG = '[EK] ', ABSAGE = '[ABSAGE] ', LYMPHDRAINAGE = '[LD] ', DOMIZIL = '[D] ', GLAD = '[GLAD] '
}

export interface IReceiptEvent {
    id: string,
    calendarId: string,
    employee: string,
    start: string, 
    end: string,
}