import { Redirect, Route } from "react-router-dom"
import { useAuth } from "../context/AuthContext"

export const ProtectedRoute = (props: any) => {
    const { currentUser } = useAuth()
    const { path } = props
  
    if (path === '/login') {
      return currentUser ? (
        <Redirect to={'dashboard/calendar'} />
      ) : (
        <Route {...props} />
      )
    }
    return currentUser ? (
      <Route {...props} />
    ) : (
      <Redirect
        to='/login'
      />
    )
}