import {Text, View, StyleSheet } from '@react-pdf/renderer';
import moment from 'moment';
import { Fragment } from 'react';

const borderColor = '#198754'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#D3D3D3',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontStyle: 'bold',
        width: '100%'
    },
    date: {
        width: '30%',
        borderRightColor: borderColor,
        textAlign: 'center'
    },
    time: {
        width: '30%',
        borderRightColor: borderColor,
        textAlign: 'center'
    },
    employee: {
        width: '30%',
        textAlign: 'center'
    },
  });

  export interface ReceiptEvent {
    start: string,
    end: string,
    employee: string
  }

  interface InvoiceItemsTableProps {
    items: ReceiptEvent[]
  }

const AppointmentTableRow = ({items}: InvoiceItemsTableProps) => {
    const getTime = (item: any) => {
        const start = moment(item.start).format("HH:mm")
        const end = moment(item.end).format("HH:mm")
        return start === end ? "Ganztägig" : `${start} - ${end}`
    }

    const orderByDate = (a: ReceiptEvent, b: ReceiptEvent) => {
        return new Date(a.start).getTime() - new Date(b.start).getTime()
    }

    const rows = items.sort(orderByDate).map( (item: any, i) => 
        <View style={styles.row} key={i}>
            <Text style={styles.date}>{moment(item.start).format("DD.MM.YYYY")}</Text>
            <Text style={styles.time}>{getTime(item)}</Text>
            <Text style={styles.employee}>{item.employee}</Text>
        </View>
    )
    return (<Fragment>{rows}</Fragment> )
};
  
export default AppointmentTableRow