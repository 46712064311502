import { BlobProvider } from '@react-pdf/renderer';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { useParams } from 'react-router-dom';
import calendarService from '../../services/calendar.service';
import patientsService from '../../services/patients.service';
import IColumn from '../../types/column.type';
import { IReceiptEvent } from '../../types/event.type';
import IPatientData from '../../types/patient.type';
import IReceipt from '../../types/receipt.type';
import { sendMessage, getEmailBody, getSMSBody } from '../../utils/message.utils';
import Receipt from '../AppointmentsDocument/Receipt';
import CustomSpinner from '../Spinner/CustomSpinner';


const Overview = () => {
    const { patientId } = useParams<{patientId: string}>()
    const [loadingPatient, setLoadingPatient] = useState(true)
    const [patient, setPatient] = useState<IPatientData>()
    const [events, setEvents] = useState<IReceiptEvent[]>()
    const [loadingEvents, setLoadingEvents] = useState(false)
    const [receipt, setReceipt] = useState<IReceipt>();
    const [message, setMessage] = useState<string>();

    const columns: IColumn[] = [
        {
            dataField: 'start',
            text: 'Datum',
            formatter: (cell: Date, row: any) => <span>{moment(cell).format("DD.MM.YYYY")}</span>
        },
        {
            dataField: 'end',
            text: 'Zeit',
            formatter: (cell: Date, row: any) => <span>{moment(row['start']).format("HH:mm")} - {moment(cell).format("HH:mm")}</span>
        },
        {
            dataField: 'employee',
            text: 'Therapeut'
        }
    ];

    useEffect(() => {
      const getPatient = async (id: string) => {
          const patientResponse = await patientsService.get(id);
          patientResponse.data() && setPatient({...patientResponse.data(), id: id} as IPatientData)
      }
      patientId && getPatient(patientId).finally(() => setLoadingPatient(false))
    }, [patientId])

    useEffect(() => {
        if (patient) {
            const getAllEventsByPatient = async () => {
                const calendarResponse = await calendarService.getAllEventsByPatient(patient)
                setEvents(calendarResponse)
            }
            setLoadingEvents(true)
            getAllEventsByPatient().finally(() => setLoadingEvents(false))
        }
      }, [patient])

      useEffect(() => {
        patient && events && setReceipt({fileName: `${patient.firstName}_${patient.lastName}.pdf`, 
                    document: events && <Receipt patient={patient} items={events} />, 
                    events: events,
                    patient: patient
                })
      }, [patient, events])

      const exportPanel = (receipt: IReceipt) => {
        const {patient} = receipt
        return (
            <>
                <div>
                    <BlobProvider document={receipt.document}>
                            {({ blob, url, loading, error }) => {
                                return (
                                    <Button className='margin-right-10px' onClick={() => url && window.open(url, "_blank")} variant='success' disabled={loading}>
                                            <i className="fas fa-file-pdf" /> {loading ? "PDF wird generiert..." : "PDF öffnen"}
                                    </Button>  
                                ) 
                            }}
                    </BlobProvider>
                    {patient.email && (
                        <Button
                            className='margin-right-10px'
                            variant='success'
                            onClick={() => receipt.events && window.open(`https://mail.google.com/mail/?view=cm&fs=1&to=${patient.email}&su=Termine physio balance&body=${getEmailBody("%0D%0A", receipt.events, patient)}`, "_blank")}
                        >
                            <i className="fas fa-envelope margin-right-10px" />Gmail öffnen
                        </Button>
                    )}
                    {patient.phone && (
                        <Button
                            className='margin-right-10px'
                            onClick={() => patient.phone && receipt.events && sendMessage(patient.phone, getSMSBody("\n", receipt.events))}
                            variant='success'
                        >
                            <i className="fas fa-comment" /> SMS versenden
                        </Button>
                    )}
                </div>
                {patient.phone && (
                    <div>
                        <div className="section-divider" />
                        <h2 className='calendar-header-title'>SMS an {patient?.firstName} {patient?.lastName} versenden</h2>
                        <Form.Group className="mb-3" controlId="to">
                            <Form.Label>An</Form.Label>
                            <Form.Control value={patient.phone} disabled />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formDescription">
                            <Form.Label>Nachricht</Form.Label>
                            <Form.Control className='heigth-150px' value={message} as="textarea" onChange={(e) => setMessage(e.currentTarget.value)} name="notes"/>
                        </Form.Group>
                        <Button
                            className='width-100pc'
                            onClick={() => patient.phone && message && sendMessage(patient.phone, message)}
                            variant='success'
                            disabled={!message}
                        >
                            <i className="fas fa-comment" /> SMS versenden
                        </Button>
                    </div>
                )}
            </>
        )
    }
    
    return (
        <div className='overview-card'>
            {loadingPatient || loadingEvents ? (
                <CustomSpinner/>
            ) : patient ? (
                <div>
                    <h2 className='calendar-header-title'>Termine für {patient?.firstName} {patient?.lastName}</h2>
                    {events ? (
                        <>
                            <BootstrapTable
                                bordered={false}
                                hover={true}
                                striped={true}
                                data={events.sort((a, b) => a.start.localeCompare(b.start))}
                                columns={columns}
                                keyField="id"
                            />
                            {receipt && exportPanel(receipt) }
                        </>
                    ) : <span>Keine Termine geplant.</span> }
                </div>
            ) : <span>Patient nicht gefunden</span>}
        </div>
    )
}

export default Overview;