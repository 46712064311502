import { useAuth } from "../../context/AuthContext";
import logo from '../../assets/logo-white.svg'

const Login = () => {
    const { signInWithGoogle } = useAuth()

    return(
        <div className="login-page">
            <div className="card login-content">
                <div className="login-logo-container">
                <img className="login-logo" src={logo} alt="react-logo" />
                </div>
                    <div className="content">
                        <button onClick={signInWithGoogle} className="google-button">
                            <div className="google-icon">
                            <i className="fab fa-google"></i>
                            </div>
                            <span className="google-button-text">
                                Sign in with Google
                            </span>
                        </button>
                    </div>
            </div>
        </div>
    )
}

export default Login;                      
