import { IGoogleEvent, LabelTitlePrefix } from "../types/event.type"

export const filterPatient = (patient: any, searchText: string) => {
    const valuesToCheck = [patient.firstName, patient.lastName, patient.address, patient.city, patient.email, patient.phone].filter(v => v)
    return searchText.split(' ').every((word: any) => valuesToCheck.some((value: any) => value.toLowerCase().includes(word.toLowerCase())))
}

// if there is a prefix for the label, append the prefix
export const genGoogleEventTitle = (event: IGoogleEvent) => `${LabelTitlePrefix[event.label as keyof typeof LabelTitlePrefix] || ""}${event.patient?.lastName} ${event.patient?.firstName}`

export const genEventTitleFromGoogleEventTitle = (googleTitle: string) => {
    const hit = Object.values(LabelTitlePrefix).find(label => googleTitle.startsWith(label))
    if (hit) {
        return googleTitle.substring(hit.length)
    }
    return googleTitle
}