import IPatientData from "../types/patient.type";
import { firestore } from "../firebase/config"
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, updateDoc, query, orderBy  } from "firebase/firestore";

const patientsCollectionRef = collection(firestore, "patients");
class PatientsService {
    getAll = () => {
      return getDocs(query(patientsCollectionRef, orderBy('lastName'), orderBy('firstName')));
    }

    get = (id: string) => {
        const patientDoc = doc(firestore, "patients", id)
        return getDoc(patientDoc);
      }
  
    create = (patient: IPatientData) => {
        return addDoc(patientsCollectionRef, patient);
    }
  
    update = (id: string, value: any) => {
        const patientDoc = doc(firestore, "patients", id)
        return updateDoc(patientDoc, value);
    }
  
    delete = (id: string) => {
        const patientDoc = doc(firestore, "patients", id)
        return deleteDoc(patientDoc);
    }
  }
  
  export default new PatientsService();