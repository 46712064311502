import { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import { gemeinden } from "../../assets/gemeinden";
import PatientsService from "../../services/patients.service";
import IPatientData from "../../types/patient.type";
import { isPhoneEmpty, isValidPhone } from "../../utils/phone.utils";

interface PatientModalProps {
    show: boolean,
    editPatient?: any,
    setShow: (e: boolean) => void,
    setReload: (e: boolean) => void
}

const PatientModal = ({show, setShow, setReload, editPatient}: PatientModalProps) => {

    const [validated, setValidated] = useState(false);
    const [patient, setPatient] = useState<IPatientData>();
    const [countryCode, setCountryCode] = useState<string>('+41');
    const phoneInputRef = useRef<HTMLInputElement>();

    useEffect(() => {
      editPatient && setPatient(editPatient)
    }, [editPatient])
    

    const onEventChange = (e: any) => {
        const name = e.target.name
        const patientObj = {...patient, [name]: name === 'blacklisted' ? e.target.checked : e.target.value} as IPatientData
        if (name === 'zip' && gemeinden[e.target.value as keyof typeof gemeinden]) {
            setPatient({...patientObj, city: gemeinden[e.target.value as keyof typeof gemeinden]})
        } else {
            setPatient(patientObj)
        }
    }

    const savePatient = async (e: any) => {
        e.preventDefault();
        setValidated(true);
        const form = e.currentTarget;
        const isFormValid = form.checkValidity();
        !isFormValid && e.stopPropagation();
        if (patient && isFormValid) {
            editPatient ? await PatientsService.update(patient.id, patient) : await PatientsService.create(patient);
            setValidated(false);
            setShow(false);
            setReload(true)
        }
    }

    const handleClose = () => {
        setShow(false)
        setPatient(editPatient)
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>Patient {editPatient ? "bearbeiten" : "hinzufügen"}</Modal.Title>
            </Modal.Header>
            <Form noValidate validated={validated} onSubmit={savePatient}>
            <Modal.Body>
                <Form.Group className="mb-3" controlId="formDescription">
                    <Form.Check checked={patient?.blacklisted} type="checkbox" label="blacklisted" name="blacklisted" onChange={onEventChange} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formFirstName">
                    <Form.Label>Vorname</Form.Label>
                    <Form.Control value={patient?.firstName} onChange={onEventChange} name="firstName" required/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formLastName">
                    <Form.Label>Nachname</Form.Label>
                    <Form.Control value={patient?.lastName} onChange={onEventChange} name="lastName" required/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formGridAddress1">
                    <Form.Label>Adresse</Form.Label>
                    <Form.Control value={patient?.address} onChange={onEventChange} name="address"/>
                </Form.Group>   
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="formPostalCode">
                            <Form.Label>Postleitzahl</Form.Label>
                            <Form.Control value={patient?.zip} onChange={onEventChange} name="zip" type="number"/>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="formCity">
                            <Form.Label>Stadt</Form.Label>
                            <Form.Control value={patient?.city} onChange={onEventChange} name="city"/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="formPhone">
                            <Form.Label>Telefon</Form.Label>
                            <PhoneInput
                                country={'ch'}
                                specialLabel=""
                                value={patient?.phone || countryCode}
                                preferredCountries={['ch', 'de']}
                                countryCodeEditable={false}
                                onChange={(value: string, country: any, e: any, formattedValue: string) => {
                                    setCountryCode('+' + country.dialCode)
                                    patient && setPatient({...patient, phone: !isPhoneEmpty(country, value) ? `+${value}` : ''})
                                }}
                                inputProps={{ref: phoneInputRef}}
                                isValid={(value, country: any) => {
                                    phoneInputRef.current && phoneInputRef.current.setCustomValidity("")
                                    if (isPhoneEmpty(country, value)) {
                                        return true
                                    }
                                    if (!isValidPhone(country, `+${value}`)) {
                                        phoneInputRef.current && phoneInputRef.current.setCustomValidity("Invalid")  
                                        return false
                                    }
                                    return true
                                }}
                            />
                            
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="formEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control value={patient?.email} onChange={onEventChange} type="email" name="email"/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Form.Group className="mb-3" controlId="formDescription">
                        <Form.Label>Notiz</Form.Label>
                        <Form.Control value={patient?.notes} as="textarea" onChange={onEventChange} name="notes"/>
                    </Form.Group>
                </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='success' type="submit">Speichern</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default PatientModal;