import { firestore } from "../firebase/config"
import { addDoc, collection, getDocs, query, orderBy, where  } from "firebase/firestore";
import IMessage from "../types/message.type";

const messagesCollectionRef = collection(firestore, "messages");
class MessagesService {
  getAll = (startTime?: Date) => {
      return getDocs(query(messagesCollectionRef, where("delivery.startTime", '>=', startTime), orderBy('delivery.startTime', 'desc')));
  }
  create = (message: IMessage) => {
      return addDoc(messagesCollectionRef, message);
  }
}
  
  export default new MessagesService();