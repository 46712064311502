import { useCallback, useEffect, useState } from 'react';
import FullCalendar, { DateSelectArg, EventApi } from '@fullcalendar/react' 
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import chLocale from '@fullcalendar/core/locales/de';
import CustomSpinner from '../Spinner/CustomSpinner'
import { Badge, Button, Modal, Spinner } from 'react-bootstrap';
import moment from 'moment';
import CalendarService from '../../services/calendar.service';
import {IEvent, IGoogleEvent, Label } from '../../types/event.type';
import ICalendar from '../../types/calendar.type';
import Select from 'react-select';
import { DELIMITER, EventModal } from '../Modals/EventModal';
import { usePatients } from '../../context/PatientContext';
import { useHistory } from 'react-router-dom';
import { genEventTitleFromGoogleEventTitle } from '../../utils/patient.utils';

const Dashboard = () => {
    const history = useHistory()
    const [events, setEvents] = useState<IEvent[]>();
    const [currentRange, setCurrentRange] = useState<{ start: string, end: string }>({start: moment().startOf('isoWeek').toISOString(), end: moment().endOf('isoWeek').toISOString()});
    const [calendars, setCalendars] = useState<ICalendar[]>();
    const [selectedCalendars, setSelectedCalendars] = useState<ICalendar[]>();
    const [newEvent, setNewEvent] = useState<IGoogleEvent>();
    const [selectedEvent, setSelectedEvent] = useState<EventApi>();
    const [show, setShow] = useState(false);
    const [showEventActions, setShowEventActions] = useState(false);
    const {selectedPatient} = usePatients();
    const [eventCounter, setEventCounter] = useState<{isLoading: boolean, counter: number}>({isLoading: false, counter: 0})

    useEffect(() => {
        const getCalendarIds = async () => {
            const calendars = await CalendarService.getAllCalendars();
            setCalendars(calendars)
            setSelectedCalendars(calendars?.filter(calendar => calendar.selected))
        }
        getCalendarIds()
    }, []);

    const reloadAllCalendarEvents = useCallback(
        async (calendars: ICalendar[]) => {
            setEvents(await CalendarService.getAllEvents(calendars, currentRange.start, currentRange.end))    
        }, [currentRange, setEvents]
    )

    useEffect(() => {
        const getAllEventsByPatient = async () => {
            const events = await CalendarService.getAllEventsByPatient(selectedPatient)
            setEventCounter({isLoading: false, counter: events?.length || 0})
        }
        selectedPatient && getAllEventsByPatient()
    }, [selectedPatient, events]);
    
    useEffect(() => {
        selectedCalendars && reloadAllCalendarEvents(selectedCalendars)
    }, [selectedCalendars, reloadAllCalendarEvents]);

    const handleShow = () => setShow(true);

    const handleEventDrop = async (e: any) => {
        if (e.oldEvent._instance && e.event._instance) {
            const {
              start: newStart,
              end: newEnd
            } = e.event._instance.range;
            const updatedEvent = {
                summary: e.oldEvent._def.title,
                start: {
                    ...(!e.event.allDay ? {dateTime: newStart.toISOString().substring(0,19)} : {date: moment(newStart).format("YYYY-MM-DD")}),
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
                }, 
                end: {
                    ...(!e.event.allDay ? {dateTime: newEnd.toISOString().substring(0,19)} : {date: moment(newEnd).format("YYYY-MM-DD")}),
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
                },
                description: e.oldEvent._def.extendedProps.description,
                extendedProperties: {
                    shared: {patientId: e.oldEvent._def.extendedProps.patientId}
                }}
            await CalendarService.updateEvent(e.oldEvent._def.extendedProps.calendarId, e.oldEvent._def.publicId, updatedEvent);
        }
    }

    const handleEventResize = async (e: any) => {
        const {event} = e
        const {
            start: newStart,
            end: newEnd
          } = event._instance.range;
          const resizedEvent = {
            summary: event._def.title,
            start: {
                dateTime: newStart.toISOString().substring(0,19),
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            }, 
            end: {
                dateTime: newEnd.toISOString().substring(0,19),
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            description: e.oldEvent._def.extendedProps.description,
            extendedProperties: {
                shared: {patientId: e.oldEvent._def.extendedProps.patientId}
            }
        }
        await CalendarService.updateEvent(e.oldEvent._def.extendedProps.calendarId, e.oldEvent._def.publicId, resizedEvent);
    }

    const removeEvent = async (calendarId: string, eventId: string) => {
        if (window.confirm("Möchten Sie dieses Ereignis wirklich löschen?")) {
            await CalendarService.deleteEvent(calendarId, eventId);
            setShowEventActions(false)
            reloadAllCalendarEvents(selectedCalendars || calendars || [])
        }
    }

    const getDotColor = (props: any) => {
        if (!props.patientId) return "gray"
        switch (props.extendedProperties.label) {
            case Label.ERSTBEHANDLUNG:
               return "yellow" 
            case Label.LYMPHDRAINAGE:
               return "blue" 
            case Label.DOMIZIL:
               return "green"
            case Label.ABSAGE:
               return "red"
            case Label.GLAD:
                return "orange"
        }
    }

    const getEventTitle = (e: any) => {
        if (!e.event._def.extendedProps.patientId) return e.event.title
        return genEventTitleFromGoogleEventTitle(e.event.title)
    }

    const eventRenderer = (e: any) => {
        if (e.event._def.extendedProps.isReadOnly) return
        const dotColor = getDotColor(e.event._def.extendedProps)
        const eventLabel = e.event._def.extendedProps.extendedProperties.label;

        switch (e.view.type) {
            case "timeGridWeek":
                return (
                    <div className='flex event-container'>
                        <div className='fc-event-main-frame'>
                            <div className={`flex align-items-center fc-event-time ${eventLabel === Label.ABSAGE ? 'strikethrough' : ''}`}>
                                {e.event._def.extendedProps.description && e.event._def.extendedProps.description.split(DELIMITER)[0] && <span>&nbsp;<i className="fas fa-exclamation" />&nbsp;</span>}
                                {dotColor && <div className={`margin-right-5px ${dotColor}-dot`}/>}
                                {e.timeText.split(" ")[0]}
                            </div>
                            <div className={`fc-event-title ${eventLabel === Label.ABSAGE ? 'strikethrough' : ''}`}>
                                {getEventTitle(e)}
                            </div>
                        </div>
                    </div>
                )
        }
    }

    const customStyles = {
        multiValue: (styles: any, { data }: any) => {
            return {
              ...styles,
              backgroundColor: data.backgroundColor,
            };
          },
          multiValueLabel: (styles: any) => ({
            ...styles,
            color: '#ffffff'
          }),
          menu: (styles: any) => ({
              ...styles,
              zIndex: 3
          })
      }
    return (
        <div className='card'>
            {!events || !calendars ? (
                    <CustomSpinner/>
                ) : (
                    <>
                    <div className='calendar-header'>
                        <div className='margin-bottom-10px flex align-items-baseline'>
                            <span className='calendar-header-title'>ausgewählte Kalender:&nbsp;</span>
                            <Select
                                styles={customStyles}
                                placeholder={"keine Kalender ausgewählt"}
                                noOptionsMessage={() => "Alle Kalender bereits ausgewählt"}
                                defaultValue={selectedCalendars}
                                isMulti
                                getOptionValue={(calendar) => calendar.id}
                                getOptionLabel={(calendar) => calendar.summary}
                                onChange={(e) => setSelectedCalendars(e as ICalendar[])}
                                options={calendars}
                            />
                        </div>
                        {selectedPatient && (
                                <div className='calendar-header-title flex'>
                                    {!eventCounter.isLoading ? 
                                        <>
                                            erstellte Termine für&nbsp;<b>{selectedPatient.firstName} {selectedPatient.lastName}{selectedPatient.blacklisted ? ' ☠' : ''}</b>: 
                                            <div className='margin-left-5px'>
                                                <Badge pill bg="success">{eventCounter.counter}</Badge>
                                            </div>
                                        </> : <Spinner animation="border" className='event-counter'/>
                                        }
                                </div>
                        )}
                    </div>
                    <FullCalendar
                        plugins={[ dayGridPlugin, timeGridPlugin, interactionPlugin ]}
                        events={events}
                        locale={chLocale}
                        headerToolbar={{
                            right: "prev,next today",
                            left: "title",
                            center: ""
                        }}
                        initialView={"timeGridWeek"}
                        selectable={true}
                        select={(e: DateSelectArg) => {
                            setSelectedEvent(undefined)
                            setNewEvent({...newEvent, 
                                        start: e.startStr, 
                                        startTime: moment(e.start).format('HH:mm'),
                                        end: e.endStr, 
                                        endTime: moment(e.end).format('HH:mm'),
                                        allDay: e.allDay})
                            handleShow()
                        }}
                        eventClick={(e) => {
                            setNewEvent(undefined)
                            setEventCounter({isLoading: true, counter: 0})
                            setSelectedEvent(e.event)
                            setShowEventActions(true)
                            e.jsEvent.preventDefault()
                        }}
                        datesSet={(args) => setCurrentRange({start: args.startStr, end: args.endStr})}
                        eventDrop={handleEventDrop}
                        eventResize={handleEventResize}
                        eventContent={eventRenderer}
                        editable={true}
                        height={"100%"}
                    />
                    <div className='flex align-self-end margin-top-5px'>
                        <div className='flex align-items-center margin-right-5px'>
                            <div className={`margin-right-5px yellow-dot`}/> Erstbehandlung
                        </div>
                        <div className='flex align-items-center margin-right-5px'>
                            <div className={`margin-right-5px blue-dot`}/> Lymphdrainage
                        </div>
                        <div className='flex align-items-center margin-right-5px'>
                            <div className={`margin-right-5px green-dot`}/> Domizil
                        </div>
                        <div className='flex align-items-center margin-right-5px'>
                            <div className={`margin-right-5px orange-dot`}/> Gruppentraining
                        </div>
                        <div className='flex align-items-center margin-right-5px'>
                            <div className={`margin-right-5px red-dot`}/> Absage
                        </div>
                        <div className='flex align-items-center margin-right-5px'>
                            <div className={`margin-right-5px gray-dot`}/> Termin importiert (Google)
                        </div>
                    </div>
                    </>
                )}
                <EventModal newEvent={newEvent} editEvent={selectedEvent} show={show} setShow={setShow} setShowEventActions={setShowEventActions} calendars={selectedCalendars || calendars || []} reloadAllCalendarEvents={reloadAllCalendarEvents}/>
            <Modal show={showEventActions} onHide={() => setShowEventActions(false)}>
                <Modal.Header closeButton>
                <Modal.Title>Aktionen</Modal.Title>
                </Modal.Header>
                <Modal.Body className="export-modal-body-container">
                    {selectedEvent && (
                        <div>
                            <Button
                                variant='success'
                                className='margin-top-10px'
                                onClick={() => window.open(selectedEvent.url, "_blank")}
                                >
                                    <i className="fas fa-calendar margin-right-10px" />Google Kalender öffnen
                            </Button>
                            {selectedEvent._def.extendedProps.patientId && (
                                <>
                                    <Button
                                        variant='success'
                                        className='margin-top-10px'
                                        onClick={() => setShow(true)}
                                        >
                                            <i className="fas fa-pen margin-right-10px" />Termin editieren
                                    </Button>
                                    <Button
                                        variant='success'
                                        className='margin-top-10px'
                                        disabled={eventCounter.isLoading || !eventCounter.counter }
                                        onClick={() => history.push(`patients/${selectedEvent._def.extendedProps.extendedProperties.patientId}`)}
                                        >
                                            {eventCounter.isLoading ? "Termine werden geladen..." : <><i className="fas fa-address-book margin-right-10px" />Patient einladen</> }
                                    </Button>
                                </>
                            )}
                            <div className="section-divider" />
                            <Button
                                variant='danger'
                                className='margin-bottom-10px'
                                onClick={() => removeEvent(selectedEvent.extendedProps.calendarId, selectedEvent._def.publicId)}
                                >
                                    <i className="fas fa-trash margin-right-10px" />Termin löschen
                            </Button>
                        </div>
                    )}
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default Dashboard;