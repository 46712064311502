import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
   
    titleContainer:{
        flexDirection: 'row',
        marginTop: 24,
    },
    reportTitle:{
        fontSize: 20,
        textAlign: 'center',
    }
  });


interface AppointmentTitleProps {
    title: string
}

  const AppointmentTitle = (props: AppointmentTitleProps) => (
    <View style={styles.titleContainer}>
        <Text style={styles.reportTitle}>{props.title}</Text>
    </View>
  );
  
  export default AppointmentTitle