import { EventApi } from "@fullcalendar/react";
import { User } from "firebase/auth";
import moment from "moment";
import { useEffect, useState } from "react";
import { Modal, Form, Button, ToggleButtonGroup, ToggleButton } from "react-bootstrap"
import { Typeahead } from "react-bootstrap-typeahead";
import { useAuth } from "../../context/AuthContext";
import { usePatients } from "../../context/PatientContext";
import CalendarService from "../../services/calendar.service";
import patientsService from "../../services/patients.service";
import ICalendar from "../../types/calendar.type";
import { IGoogleEvent, Label } from "../../types/event.type";
import IPatientData from "../../types/patient.type";
import { filterPatient, genGoogleEventTitle } from "../../utils/patient.utils";
import CustomSpinner from "../Spinner/CustomSpinner";

interface EventModalProps {
    newEvent?: IGoogleEvent,
    editEvent?: EventApi,
    show: boolean,
    setShow: (e: boolean) => void,
    setShowEventActions: (e: boolean) => void,
    calendars: ICalendar[],
    reloadAllCalendarEvents: (calendars: ICalendar[]) => void
}

export const DELIMITER = "\n---------------\n"

export const EventModal = ({newEvent, editEvent, show, setShow, setShowEventActions, calendars, reloadAllCalendarEvents}: EventModalProps) => {

    const [event, setEvent] = useState<IGoogleEvent>({})
    const [validated, setValidated] = useState(false);
    const {patients, selectedPatient, setSelectedPatient} = usePatients();
    const {currentUser} = useAuth()
    const [updatedEndDate, setUpdatedEndDate] = useState<{end?: string, endTime?: string}>({});

    const radios = [
        { name : "Erstbehandlung", value: Label.ERSTBEHANDLUNG },
        { name : "Lymphdrainage", value: Label.LYMPHDRAINAGE },
        { name : "Domizil", value: Label.DOMIZIL },
        { name : "Absage", value: Label.ABSAGE },
        { name : "Gruppentraining", value: Label.GLAD },
    ]

    useEffect(() => {
        if ([Label.LYMPHDRAINAGE, Label.DOMIZIL, Label.GLAD].includes(event.label as Label)) {
            setUpdatedEndDate({end: moment(event?.start).add(1, 'hour').toISOString(), endTime: moment(event?.startTime, 'HH:mm').add(1, 'hour').format('HH:mm')})
        } else {
            setUpdatedEndDate({end: moment(event?.start).add(30, 'minutes').toISOString(), endTime:  moment(event?.startTime, 'HH:mm').add(30, 'minutes').format('HH:mm')})
        }
    }, [event.label, event.start, event.end, event?.startTime, event?.endTime])
    

    useEffect(() => {
        const getCurrentOrganizer = async () => {
            if (currentUser) {
                const userEmail = (currentUser as User).email
                const organizer = await CalendarService.getCalendar((currentUser as User).email as string)
                userEmail && setEvent((event) => ({...event, organizer: organizer}))
            }
        }
        newEvent && getCurrentOrganizer()
    }, [currentUser, newEvent])

    useEffect(() => {
        selectedPatient && setEvent((event) => ({...event, patient: selectedPatient}))
    }, [selectedPatient])

    useEffect(() => {
        newEvent && setEvent((event) => ({...newEvent, organizer: event.organizer, patient: event.patient}))
    }, [newEvent])

    useEffect(() => {
        const getSelectedEvent = async () => {
            if (editEvent && editEvent._def.extendedProps.patientId) {
               const {result} = await CalendarService.getEvent(editEvent._def.extendedProps.calendarId, editEvent._def.publicId);
               const organizer = await CalendarService.getCalendar(editEvent._def.extendedProps.calendarId)
               const patientResponse = await patientsService.get(editEvent._def.extendedProps.patientId)
               const selectedPatient = {...patientResponse.data(), id: patientResponse.id} as IPatientData;
               setSelectedPatient(selectedPatient)
               setEvent({
                description: result.description,
                patient: selectedPatient,
                startTime: moment(result.start?.dateTime).format('HH:mm'),
                endTime: moment(result.end?.dateTime).format('HH:mm'),
                organizer: organizer,
                ...(result.extendedProperties?.shared?.label && {label: result.extendedProperties.shared.label as Label}),
                ...(result.start?.dateTime && {start: result.start.dateTime}),
                ...(result.end?.dateTime && {end: result.end.dateTime}),
            })
            } else {
                setSelectedPatient(undefined)
            }
        }
        getSelectedEvent()
    }, [editEvent, setSelectedPatient])
    
    
    const handleClose = () => {
        setShow(false)
        setValidated(false)
    };

    const onEventChange = (e: any) => {
        setEvent({...event, [e.target.name]: e.target.value})
    }

    const saveEvent = async (e: any) => {
        e.preventDefault();
        setValidated(true);
        const form = e.currentTarget;
        const isFormValid = form.checkValidity();
        (!isFormValid) && e.stopPropagation()
        if (event?.organizer && isFormValid) {
            await CalendarService.createEvent({
                ...event,
                description: getEventDescription(),
                ...updatedEndDate});
            setValidated(false);
            setShow(false);
            reloadAllCalendarEvents(calendars);
        }
    }

    const updateEvent = async (e: any) => {
        e.preventDefault();
        setValidated(true);
        const form = e.currentTarget;
        const isFormValid = form.checkValidity();
        (!isFormValid) && e.stopPropagation()
        if (event?.organizer && isFormValid && editEvent) {
            const updatedEvent = {
                summary: genGoogleEventTitle(event),
                start: {
                    ...(!editEvent.allDay ? {dateTime: event.start} : {date: moment(event.start).format("YYYY-MM-DD")}),
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
                }, 
                end: {
                    ...(!editEvent.allDay ? {dateTime: updatedEndDate.end || event.end} : {date: moment(updatedEndDate.end || event.end).format("YYYY-MM-DD")}),
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
                },
                description: getEventDescription(),
                extendedProperties: {
                    shared: {patientId: event.patient?.id, label: event.label}
                }}
            await CalendarService.updateEvent(editEvent._def.extendedProps.calendarId, editEvent._def.publicId, updatedEvent);
            setValidated(false);
            setShow(false);
            setShowEventActions(false);
            reloadAllCalendarEvents(calendars);
        }
    }

    const getEventDescription = () => {
        return `${(event.description && event.description.split(DELIMITER)[0]) || ""}${(event.patient?.phone || event.patient?.email) && DELIMITER}${event.patient?.phone && `Phone: ${event.patient?.phone}`}${event.patient?.email && `\nEmail: ${event.patient?.email}`}`
    }

    const filterByCallback = (option: any, props: any) => {
        return filterPatient(option, props.text);
    }

    return (
        <Modal show={show} onHide={handleClose} dialogClassName="modal-width">
            <Modal.Header closeButton>
            <Modal.Title>Termin {event ? 'editieren' : 'hinzufügen'}</Modal.Title>
            </Modal.Header>
            <Form noValidate validated={validated} onSubmit={editEvent ? updateEvent : saveEvent}>
            {event ? (
                <>
                    <Modal.Body>
                <Form.Group className="mb-3" controlId="formTime">
                    <span className='modal-time-text'>
                        <i className="far fa-clock margin-right-10px" />
                        {event?.allDay ? "Ganztägig" : (
                            `${event?.startTime} → ${ updatedEndDate.endTime || event?.endTime}`
                        )}
                    </span>
                </Form.Group>
                <Form.Group className="content margin-bottom-10px">
                    <ToggleButtonGroup name="labelSelection" type="checkbox" value={[event.label]}>
                        {radios.map((radio, idx) => (
                            <ToggleButton
                                key={idx}
                                id={`radio-${idx}`}
                                type="radio"
                                className='toggle-button'
                                name="radio"
                                value={radio.value}
                                onChange={(e) => {
                                    const selectedLabel = e.currentTarget.value
                                    selectedLabel === event.label ? setEvent({...event, label: undefined}) : setEvent({...event, label: (e.currentTarget.value as Label)})
                                }}
                            >
                                {radio.name}
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                </Form.Group>
            
                {calendars && (
                    <Form.Group className="mb-3">
                        <Form.Label>Therapeut</Form.Label>
                        <Typeahead
                            clearButton
                            id='organizer-typehead'
                            disabled={!!editEvent}
                            labelKey={(option: any) => `${option.summary} (${option.id})`}
                            onChange={(option) => {
                                const selectedCalendar = option.length > 0 ? option[0] as ICalendar : undefined
                                setEvent({...event, organizer: selectedCalendar})
                            }}
                            options={calendars}
                            selected={event?.organizer ? [event?.organizer] : []}
                            inputProps={{ required: true }}
                        />
                    </Form.Group>
                )}
                <Form.Group className="mb-3">
                    <Form.Label>Patient</Form.Label>
                    <Typeahead
                        clearButton
                        id='patient-typehead'
                        labelKey={(option: any) => {
                            let label = `${option.blacklisted ? '☠ ' : ''}${option.firstName} ${option.lastName || ""}`;
                            const info = option.phone ? option.phone : option.email ? option.email : ""
                            return info ? label.concat(` (${info})`) : label
                        }}
                        onChange={(option) => {
                            const selectedPatient = option.length > 0 ? option[0] as IPatientData : undefined
                            setSelectedPatient(selectedPatient)
                        }}
                        options={patients}
                        selected={selectedPatient ? [selectedPatient] : []}
                        inputProps={{ required: true }}
                        filterBy={filterByCallback}
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formDescription">
                    <Form.Label>Beschreibung</Form.Label>
                    <Form.Control defaultValue={event.description?.split(DELIMITER)[0]} as="textarea" onChange={onEventChange} name="description"/>
                </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='success' type="submit">Speichern</Button>
                </Modal.Footer>
                </>
            ): <CustomSpinner/>}
            </Form>
        </Modal>
    )
}