import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
   
    titleContainer:{
        position: 'absolute',
        bottom: 30,
        left:60,
        right:60
    },
    reportTitle:{
        fontSize: 10,
    },
    disclaimer: {
        marginTop: 12,
        fontSize: 10
    }
  });

  const FinalMsg = () => (
    <>
    <View style={styles.titleContainer}>
            <Text style={styles.reportTitle}>
                Im Verhinderungsfall bitte 24 Stunden vorher Bescheid geben.
            </Text>
            <Text style={styles.disclaimer}>
                Unsere Leistungen werden direkt elektronisch mit der zuständigen Versicherung abgerechnet. Auf Wunsch können wir Ihnen eine zusätzliche Rechnungskopie ausstellen.
            </Text>
    </View>
    </>
  );
  
  export default FinalMsg