import { useState } from "react";
import CustomSpinner from "../Spinner/CustomSpinner";
import ActionsFormatter from "../ActionsFormatter/ActionsFormatter";
import BootstrapTable from 'react-bootstrap-table-next';
// @ts-ignore https://github.com/webpack/webpack/issues/12197
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
// @ts-ignore
import paginationFactory from 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator';
import { Button } from "react-bootstrap";
import PatientService from "../../services/patients.service";
import IPatientData from "../../types/patient.type";
import PatientModal from "../Modals/PatientModal";
import IColumn from "../../types/column.type";
import { usePatients } from "../../context/PatientContext";
import { filterPatient } from "../../utils/patient.utils";

const Patients = () => {
    const [show, setShow] = useState(false);
    const {patients, reload, setReload, searchInput, setSearchInput} = usePatients();
    const [selectedPatient, setSelectedPatient] = useState<IPatientData>();

    const columns: IColumn[] = [{
        dataField: 'blacklistText',
        text: '',
        headerStyle: () => {
          return { width: '30px' };
        },
        style: {
            fontSize: '25px'
        }
      },{
        dataField: 'lastName',
        text: 'Nachname'
      },{
        dataField: 'firstName',
        text: 'Vorname'
      }, {
        dataField: 'address',
        text: 'Adresse'
      }, {
        dataField: 'city',
        text: 'Stadt'
    }, {
        dataField: 'zip',
        text: 'Postleitzahl'
    }, {
        dataField: 'phone',
        text: 'Telefon'
    }, {
        dataField: 'email',
        text: 'Email'
    },
    {
        dataField: 'notes',
        text: 'Notizen'
    },
     {
        dataField: 'actions',
        text: '',
        editable: false,
        csvExport: false,
        isDummyField: true,
        formatter: (cell: any, row: any) => <ActionsFormatter row={row} editPatient={editPatient} deletePatient={deletePatient}/>,
        headerStyle: (column: any, colIndex: any) => {
            return { width: '80px' }; 
        }
      }];

    const handleShow = () => {
        setShow(true)
        setSelectedPatient(undefined)
    };

    const deletePatient = async (patientId: string) => {
        if (window.confirm("Möchten Sie diesen Patienten wirklich löschen?")) {
            await PatientService.delete(patientId)
            setReload(true)
        }
    }

    const editPatient = async (patient: any) => {
        setSelectedPatient(patient)
        setShow(true)
    }

    const onColumnMatch = ({
        searchText,
        value,
        column,
        row
      }: any) => {
          setSearchInput(searchText)
          return filterPatient(row, searchText)
      }

    return (
        <div className='card'>
            {!patients || reload ? (
                <CustomSpinner/>
            ) : (
                <div>
                    <ToolkitProvider
                        keyField="resourceName"
                        data={ patients.map(patient => ({...patient, blacklistText: patient.blacklisted ? '☠': ''})) }
                        columns={ columns }
                        exportCSV={ {
                            fileName: 'patienten.csv',
                            separator: ';'
                          } }
                        search={{onColumnMatch: onColumnMatch, defaultSearch: searchInput}} >
                        {
                            (props: any) => (
                            <div>
                                <div className='flex space-between'>
                                    <Search.SearchBar { ...props.searchProps } placeholder={"Suchen"} style={{width: 250}}/>
                                    <div>
                                    <Button onClick={handleShow} className="margin-right-10px" variant='success'>
                                        <i className="fas fa-user-plus margin-right-10px" />
                                        Patient hinzufügen
                                    </Button>
                                    <Button onClick={() => props.csvProps.onExport()} variant='success'>
                                        <i className="fas fa-file-csv margin-right-10px" />
                                        Patienten exportieren
                                    </Button>
                                    </div>
                                </div>
                                <hr />
                                <BootstrapTable
                                    bordered={false}
                                    hover={true}
                                    striped={true}
                                    pagination={ paginationFactory() }
                                    { ...props.baseProps }
                                />
                            </div>
                            )
                        }
                    </ToolkitProvider>
                    <PatientModal editPatient={selectedPatient} show={show} setShow={setShow} setReload={setReload}/>
                </div>
            )}
        </div>
    );
}

export default Patients;