import Main from './layouts/Main'
import Login from './components/Login/Login'
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import {ProtectedRoute} from './routes/ProtectedRoute'
import {AuthContextProvider} from './context/AuthContext'
import {PatientContextProvider} from './context/PatientContext'


const App = () => {
  
  return (
    <AuthContextProvider>
      <PatientContextProvider>
      <Router>
        <Switch>
          <ProtectedRoute exact path='/login' component={Login} />
          <ProtectedRoute path="/dashboard" render={(props: any) => <Main {...props} />}/>
          <Redirect from='/' to='/dashboard/calendar'/>
        </Switch>
       </Router>
      </PatientContextProvider>
    </AuthContextProvider>
  );
}

export default App;