import {Text, View, StyleSheet } from '@react-pdf/renderer';
import IPatientData from '../../types/patient.type';

const styles = StyleSheet.create({
    headerContainer: {
        marginTop: 15
    },
  });

  export interface Patient {
    firstName: string,
    lastName: string,
    address: string,
    city: string,
    postalCode: string,
    phone: string,
    email: string,
  }

  interface PatientInfoProps {
    patient: Patient | IPatientData
  }

  const PatientInfo = ({patient}: PatientInfoProps) => (
    <View style={styles.headerContainer}>
        <Text>{patient.firstName} {patient.lastName}</Text>
    </View>
  );
  
  export default PatientInfo