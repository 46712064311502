import { useEffect, useState } from "react";
import CustomSpinner from "../Spinner/CustomSpinner";
import BootstrapTable from 'react-bootstrap-table-next';
// @ts-ignore https://github.com/webpack/webpack/issues/12197
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
// @ts-ignore
import paginationFactory from 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator';
import MessageService from "../../services/message.service";
import IMessage from "../../types/message.type";
import { Badge } from "react-bootstrap";
import moment from "moment";
import { Timestamp } from "firebase/firestore";
import IColumn from "../../types/column.type";

const History = () => {
    const [reload, setReload] = useState(true);
    const [messages, setMessages] = useState<IMessage[]>()

    const columns: IColumn[] = [{
        dataField: 'to',
        text: 'An',
        headerStyle: (column: any, colIndex: any) => {
            return { width: '150px' }; 
        }
      }, {
        dataField: 'delivery.startTime',
        text: 'Zeit',
        formatter: (cell: Timestamp, row: any) => <span>{moment(cell.toDate()).format("DD.MM.YYYY HH:mm")}</span>,
        headerStyle: (column: any, colIndex: any) => {
            return { width: '150px' }; 
        }
      }, {
        dataField: 'body',
        text: 'Nachricht'
      }, {
        dataField: 'delivery.errorMessage',
        text: 'Fehler'
      }, {
        dataField: 'delivery.state',
        text: 'Status',
        formatter: (cell: string, row: any) => <Badge bg={`${cell === "SUCCESS" ? "success" : cell === "ERROR" ? "danger" : "secondary"}`}>{cell === "SUCCESS" ? "erfolgreich" : cell === "ERROR" ? "fehlgeschlagen" : "Ausstehend"}</Badge>,
        headerStyle: (column: any, colIndex: any) => {
            return { width: '110px' }; 
        }
    }];

    useEffect(() => {
        reload && fetchMessages()
    }, [reload]);

    const fetchMessages = async () => {
        const messages = await MessageService.getAll(moment().subtract(7, 'days').toDate());
        setMessages(messages.docs.map(doc => doc.data() as IMessage));
        setReload(false)
    }

    return (
        <div className='card'>
            {reload ? (
                <CustomSpinner/>
            ) : (
                <div>
                    <ToolkitProvider
                        keyField="resourceName"
                        data={ messages }
                        columns={ columns }
                        exportCSV
                        search >
                        {
                            (props: any) => (
                            <div>
                                <BootstrapTable
                                    bordered={false}
                                    hover={true}
                                    striped={true}
                                    pagination={ paginationFactory() }
                                   
                                    { ...props.baseProps }
                                />
                            </div>
                            )
                        }
                    </ToolkitProvider>
                </div>
            )}
        </div>
    );
}

export default History;