import { NavLink, useLocation } from 'react-router-dom';
import {IRoute} from '../../routes/routes';
import logo from '../../assets/logo-white.svg'
import { Nav } from 'react-bootstrap';

interface SidebarProps {
  routes: IRoute[]
}

const Sidebar = (props: SidebarProps) => {
  const location = useLocation();
  const {routes} = props;
  const activeRoute = (routeName: string) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  const renderRoutes = () => {
    return (
      <Nav>
        {routes.map((prop, key) => {
          return (
            <li
              className={
                activeRoute(prop.layout + prop.path)
              }
              key={key}
            >
            <NavLink
                to={prop.layout + prop.path}
                className="nav-link"
                activeClassName="active">
                <i className={prop.icon}/>
                <p>{prop.name}</p>
            </NavLink>
            </li>
          );
        })}      
      </Nav>
    )
  }

    return (
        <div
        className="sidebar"
        data-color={"7bb064"}
      >
        <div className="logo">
          <a
            href="https://physiobalance.fit"
            className="simple-text logo-mini"
          >
            <div className="logo-img">
              <img src={logo} alt="react-logo" />
            </div>
          </a>
        </div>
        <div className="sidebar-wrapper">
          {renderRoutes()}
        </div>
      </div>
    );
}

export default Sidebar;